<template>
  <v-row>

    <v-dialog class="card-pedidos-filial" v-model="detalhesPedido" scrollable>
      <v-card color="primary" class="white--text">
        <v-progress-linear indeterminate absolute :active="carregandoPedidos"/>
        <v-card-title class="text-truncate">Detalhe Pedidos Feitos
          <v-spacer/>
        </v-card-title>
        <v-card-text class="pa-0" v-if="dadosPedidos.totalpedidocompra > 0">

          <template class="elevation-0">
            <v-data-table
                :headers="headersAnalitico"
                :items="dadosPedidos.pedidocompra"
                item-key="idpedido"
                class="elevation-0"
                sortable="false"
                hide-details
                hide-default-footer
                dense
                :items-per-page="itemsPerPageDet"
                height="70vh"
                fixed-header
                :expanded.sync="expanded"
                @click:row="handleRowClick"
                single-expand
                :item-class="rowClass"
            >

              <template v-slot:header.idempresa="{ header }">
                <span class="header">{{ header.text }}</span>
              </template>
              <template v-slot:header.empresa="{ header }">
                <span class="header">{{ header.text }}</span>
              </template>
              <template v-slot:header.idclifor="{ header }">
                <span class="header">{{ header.text }}</span>
              </template>
              <template v-slot:header.cliente="{ header }">
                <span class="header">{{ header.text }}</span>
              </template>
              <template v-slot:header.idpedido="{ header }">
                <span class="header">{{ header.text }}</span>
              </template>
              <template v-slot:header.dtmovimento="{ header }">
                <span class="header">{{ header.text }}</span>
              </template>
              <template v-slot:header.pagamento="{ header }">
                <span class="header">{{ header.text }}</span>
              </template>
              <template v-slot:header.valor="{ header }">
                <span class="header">{{ header.text }}</span>
              </template>



              <template v-slot:item.idempresa="{ item }">
                <span class="body">{{ item.idempresa }}</span>
              </template>
              <template v-slot:item.empresa="{ item }">
                <span class="body" nowrap>{{ item.empresa }}</span>
              </template>
              <template v-slot:item.idclifor="{ item }">
                <span class="body">{{ item.idclifor }}</span>
              </template>
              <template v-slot:item.cliente="{ item }">
                <span class="body" nowrap>{{ item.cliente }}</span>
              </template>
              <template v-slot:item.idpedido="{ item }">
                <span class="body">{{ item.idpedido }}</span>
              </template>
              <template v-slot:item.dtmovimento="{ item }">
                <span class="body">{{ item.dtmovimento }}</span>
              </template>
              <template v-slot:item.pagamento="{ item }">
                <span class="body" nowrap>{{ item.pagamento }}</span>
              </template>
              <template v-slot:item.valor="{ item }">
                <span class="body">{{ item.valor | formataDinheiro }}</span>
              </template>

              <!-- Slot para os totais -->
              <template #body.append>
                <tr>
                  <td class="footer"><strong>Total:</strong></td>
                  <td colspan="6"></td>
                  <td class="footer"><strong>{{ dadosPedidos.totalpedidocompra | formataDinheiro }}</strong></td>
                </tr>
              </template>

              <!-- Itens expandidos -->
              <template template v-slot:expanded-item="{}">
                <td colspan="10" class="px-0 primary">
                  <v-simple-table class="tableExp">
                    <thead class="headerExp">
                    <tr class="cursor-normal">
                      <th nowrap>Pedido</th>
                      <th nowrap>Titulo</th>
                      <th nowrap>Cod.</th>
                      <th nowrap>Pagamento</th>
                      <th nowrap>Valor</th>
                      <th nowrap>Data Movimento</th>
                      <th nowrap>Vencimento</th>
                      <th nowrap>Prazo</th>
                    </tr>
                    </thead>
                    <tbody class="bodyExp text-capitalize">
                    <tr v-if="carregandoPagamentos">
                      <td colspan="100%"><v-skeleton-loader class="white" type="table-row" /></td>
                    </tr>
                    <tr v-for="(item, index) in dadosFormaPagamento" :key="index"
                        class="cursor-normal" v-else>
                      <td>{{ item.idPedido || "" }}</td>
                      <td>{{ item.idtitulo || "" }}</td>
                      <td>{{ item.idpagamento || "" }}</td>
                      <td>{{ item.pagamento || "" }}</td>
                      <td>{{ item.valor | formataDinheiro }}</td>
                      <td>{{ item.dtmovimento || "" }}</td>
                      <td>{{ item.dtvencimento || "" }}</td>
                      <td>{{ item.prazos || "" }}</td>
                    </tr>
                    </tbody>
                    <tfoot class="footExp text-capitalize" v-if="dadosFormaPagamento">
                    <tr class="cursor-normal">
                      <td nowrap class="bold-text">Total:</td>
                      <td nowrap colspan="3"></td>
                      <td nowrap class="bold-text">{{ dadosFormaPagamento.map(v =>
                          v.valor).reduce((a, v) => a + v, 0) || 0 | formataDinheiro }}</td>
                      <td nowrap colspan="3"></td>
                    </tr>
                    </tfoot>

                  </v-simple-table>
                </td>
              </template>

            </v-data-table>
          </template>


        </v-card-text>
        <v-card-text v-else>
          <v-simple-table class="pa-0 rounded-0" dense fixed-header height="70vh">
            <template v-slot:default>
              <thead class="header">
              <tr>
                <th>Filial</th>
                <th>Cód. Fornecedor</th>
                <th>Fornecedor</th>
                <th>Data Pedido</th>
                <th>Form. Pagamento</th>
                <th>Valor</th>
              </tr>
              </thead>
              <tbody class="body text-capitalize">
              <tr>
                <td class="text-center" colspan="6">Nenhum registro encontrado.</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions >
          <v-spacer />
          <v-btn :loading="carregandoPedidos" class="px-3" elevation="0"
                 @click="detalhesPedido = false, expanded = []">Fechar</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog class="card-pedidos-filial" v-model="detalhesLancamento" scrollable>
      <v-card color="primary" class="white--text">
        <v-progress-linear indeterminate absolute :active="carregandoPedidos"/>
        <v-card-title class="text-truncate">Detalhe Mercadorias Recebidas
          <v-spacer/>
        </v-card-title>
        <v-card-text class="pa-0" v-if="dadosPedidos.totalnotaslancada > 0">
          <v-simple-table class="pa-0 rounded-0" dense fixed-header height="70vh">
            <template v-slot:default>
              <thead class="header">
              <tr>
                <th class="">Filial</th>
                <th class="pr-0">Cód. Fornecedor</th>
                <th class="pr-0">Fornecedor</th>
                <th class="pr-0">Data Pedido</th>
                <th class="pr-0">Dias para Entrega</th>
                <th class="pr-0">Form. Pagamento</th>
                <th>Valor</th>
              </tr>
              </thead>
              <tbody class="body text-capitalize">
              <tr v-for="(d, i) in dadosPedidos.notaslancada" :key="i">
                <td nowrap>{{ d.empresa }}</td>
                <td nowrap>{{ d.idclifor }}</td>
                <td nowrap>{{ d.cliente }}</td>
                <td>{{ d.dtmovimento }}</td>
                <td>{{ d.diasentrega }}</td>
                <td nowrap>{{ d.pagamento }}</td>
                <td>{{ d.valor | formataDinheiro }}</td>
              </tr>
              </tbody>
              <tfoot class="footThree text-capitalize" v-if="dadosPedidos.totalnotaslancada > 0">
              <tr class="font-weight-bold">
                <td colspan="3">Total:</td>
                <td colspan="3"></td>
                <td>{{ dadosPedidos.totalnotaslancada | formataDinheiro }}</td>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text v-else>
          <v-simple-table class="pa-0 rounded-0" dense fixed-header height="70vh">
            <template v-slot:default>
              <thead class="header">
              <tr>
                <th>Filial</th>
                <th>Cód. Fornecedor</th>
                <th>Fornecedor</th>
                <th>Data Pedido</th>
                <th>Form. Pagamento</th>
                <th>Valor</th>
              </tr>
              </thead>
              <tbody class="body text-capitalize">
              <tr>
                <td class="text-center" colspan="6">Nenhum registro encontrado.</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions >
          <v-spacer />
          <v-btn :loading="carregandoPedidos" class="px-3" elevation="0"
                 @click="detalhesLancamento = false">Fechar</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- FILTROS -->
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" class="pb-0 d-flex">
                <v-col class="pa-0 pl-3 pb-2">
                  <InputDatePicker
                      :disabled="carregando"
                      v-model="busca.dtinicio"
                      label="Data Inicial"
                      :outlined="true"
                      :dense="true"
                  />
                </v-col>
                <v-col class="pa-0 pl-3 pb-2">
                  <InputDatePicker
                      :disabled="carregando"
                      v-model="busca.dtfim"
                      label="Data Final"
                      :outlined="true"
                      :dense="true"
                  />
                </v-col>
                <v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
                  <v-sheet class="text-center" height="100%">
                    <v-row class="pa-3">
                      <v-col cols="6">
                        <InputDatePicker
                            :disabled="carregando"
                            v-model="busca.dtinicio"
                            label="Período"
                            :outlined="true"
                            :dense="true"
                        />
                      </v-col>
                      <v-col cols="6">
                        <InputDatePicker
                            :disabled="carregando"
                            v-model="busca.dtfim"
                            label="Período"
                            :outlined="true"
                            :dense="true"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                            text
                            class="mt-n3"
                            color="primary"
                            @click="sheet = !sheet"
                        >Cancelar
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                            text
                            class="mt-n3"
                            color="primary"
                            @click="listar(), sheet = !sheet"
                        >Filtrar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-bottom-sheet>
                <v-col class="pa-0 pl-3 pb-2">
                  <v-select :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial"
                            outlined dense v-model="busca.idfiliais" hide-details multiple
                            class="single-line-selection">
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 0">{{ item.filial }}</span>
                      <span v-if="index === 1" class="grey--text text-caption">
										(+{{ busca.idfiliais.length - 1 }})
									</span>
                    </template>

                    <template #prepend-item>
                      <v-list-item class="pr-0" dense>
                        <v-list-item-action>
                          <v-checkbox v-model="selectAllChecked"
                                      @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content @click="toggleSelectAll('LABEL')">
                          <!-- Use uma tag label para vincular ao checkbox -->
                          <label class="text-subtitle-2">Todas</label>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-btn
                    :disabled="carregando"
                    class="ml-2 mt-1"
                    color="primary"
                    elevation="0"
                    fab
                    x-small
                    @click="listar()"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

    </v-col>

    <!-- TABLES -->
    <v-col cols="12">
      <v-card class="primary">
        <v-card-title class="white--text">
          Detalhe Orçamento Compras
        </v-card-title>
        <v-divider class="white" />
        <v-card-text class="pa-2">
          <v-expansion-panels v-model="panels" single class="elevation-0">

            <!-- PAINEL ORÇAMENTO COMPRAS-->
            <v-expansion-panel active-class="primary white--text rounded-0">
              <v-expansion-panel-header class="py-4 pa-2" hide-actions v-model="headerOpen" v-slot="{ open }">
                <v-row align="center" no-gutters>
                  <v-col cols="4" class="text-start text-h6 pl-4">Mercadorias Recebidas</v-col>
                  <v-col cols="8">
                    <v-row v-if="!open">
                      <v-col class="text-center font-weight-bold text-h6 ">Teto Orçamento <br />
                        <v-chip color="primary" class="mt-1">
                          <template v-if="!carregando">
                            {{ dados.totalmeta || 0 | formataDinheiro}}
                          </template>
                          <template v-else>
                            <v-progress-circular indeterminate size="16" color="white"></v-progress-circular>
                          </template>
                        </v-chip>
                      </v-col>
                      <v-col class="text-center font-weight-bold text-h6"> Total Gasto <br />
                        <v-chip color="primary" class="mt-1">
                          <template v-if="!carregando">
                            {{ dados.totalnotaslancada || 0 | formataDinheiro}}
                          </template>
                          <template v-else>
                            <v-progress-circular indeterminate size="16"
                                                 color="white"></v-progress-circular>
                          </template>
                        </v-chip>
                      </v-col>
                      <v-col class="text-center font-weight-bold text-h6">Saldo <br />
                        <v-chip color="primary" class="mt-1">
                          <template v-if="!carregando">
                            {{ dados.totalmeta - dados.totalnotaslancada || 0 | formataDinheiro}}
                          </template>
                          <template v-else>
                            <v-progress-circular indeterminate size="16" color="white"></v-progress-circular>
                          </template>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="elevation-0">
                <v-skeleton-loader v-if="carregando" type="table-tbody"/>
                <v-card v-if="carregando">
                  <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
                </v-card>
                <v-card v-else class="elevation-0 pa-0">
                  <template v-if="dados.totalnotaslancada > 0" class="elevation-0">
                    <v-data-table
                        :headers="headersSintetico"
                        :items="dados.notaslancada"
                        item-key="idempresa"
                        class="elevation-0"
                        hide-details
                        hide-default-footer
                        dense
                        :items-per-page="itemsPerPage"
                        height="70vh"
                        fixed-header
                        @click:row="detalhesFilialLancado"
                    >
                      <template v-slot:header.idempresa="{ header }">
                        <span class="header">{{ header.text }}</span>
                      </template>
                      <template v-slot:header.empresa="{ header }">
                        <span class="header">{{ header.text }}</span>
                      </template>
                      <template v-slot:header.valor="{ header }">
                        <span class="header">{{ header.text }}</span>
                      </template>
                      <template v-slot:header.qtdnota="{ header }">
                        <span class="header">{{ header.text }}</span>
                      </template>

                      <template v-slot:item.idempresa="{ item }">
                        <span class="body">{{ item.idempresa }}</span>
                      </template>
                      <template v-slot:item.empresa="{ item }">
                        <span class="body">{{ item.empresa }}</span>
                      </template>
                      <template v-slot:item.valor="{ item }">
                        <span class="body">{{ item.valor | formataDinheiro }}</span>
                      </template>
                      <template v-slot:item.qtdnota="{ item }">
                        <span class="body">{{ item.qtdnota }}</span>
                      </template>
                      <!-- Slot para os totais -->
                      <template #body.append>
                        <tr>
                          <td class="footer"><strong>Total:</strong></td>
                          <td colspan="1"></td>
                          <td class="footer"><strong>{{ dados.totalnotaslancada | formataDinheiro }}</strong></td>
                          <td class="footer"><strong>{{ dados.totalqtdnotaslancada }}</strong></td>
                        </tr>
                      </template>
                    </v-data-table>
                  </template>
                  <template v-else>
                    <v-simple-table dense light>
                      <thead>
                      <tr>
                        <th class="text-center">Cód. Filial</th>
                        <th class="text-center">Filial</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Qtd. Nota</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template>
                        <tr>
                          <td class="text-center" colspan="4">Nenhum registro encontrado.</td>
                        </tr>
                      </template>
                      </tbody>
                    </v-simple-table>
                  </template>
                </v-card>

              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- PAINEL PEDIDOS DE COMPRA-->
            <v-expansion-panel active-class="primary white--text rounded-0">

                <v-expansion-panel-header class="py-4 pa-2" v-model="headerOpen" v-slot="{ open }" hide-actions>
                  <v-row align="center" no-gutters>
                    <v-col cols="4" class="text-start text-h6 pl-4">Pedidos de Compra</v-col>
                    <v-col cols="8">
                      <v-row v-if="!open">
                        <v-col class="text-center font-weight-bold text-h6 ">Orçamento Disponível <br />
                          <v-chip color="primary" class="mt-1">
                            <template v-if="!carregando">
                              {{ (dados.totalmeta || 0) - (dados.totalnotaslancada || 0) | formataDinheiro}}
                            </template>
                            <template v-else>
                              <v-progress-circular indeterminate size="16"
                                                   color="white"></v-progress-circular>
                            </template>
                          </v-chip>
                        </v-col>
                        <v-col class="text-center font-weight-bold text-h6"> Total Pedido <br />
                          <v-chip color="primary" class="mt-1">
                            <template v-if="!carregando">
                              {{ dados.totalpedidocompra || 0 | formataDinheiro}}
                            </template>
                            <template v-else>
                              <v-progress-circular indeterminate size="16"
                                                   color="white"></v-progress-circular>
                            </template>
                          </v-chip>
                        </v-col>
                        <v-col class="text-center font-weight-bold text-h6">Saldo Previsto <br />
                          <v-chip color="primary" class="mt-1">
                            <template v-if="!carregando">
                              {{ (dados.totalmeta - (dados.totalnotaslancada || 0)) - dados.totalpedidocompra || 0 | formataDinheiro}}
                            </template>
                            <template v-else>
                              <v-progress-circular indeterminate size="16" color="white"></v-progress-circular>
                            </template>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-skeleton-loader v-if="carregando" type="table-tbody"/>
                <v-card v-if="carregando">
                  <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
                </v-card>
                <v-card v-else class="elevation-0 pa-0">
                  <template>
                    <v-col cols="12" class="pb-0 d-flex">
                      <v-col class="pa-0 pl-3 pb-2">
                        <InputDatePicker
                            :disabled="carregando"
                            v-model="busca.dtini2"
                            label="Data Inicial"
                            :outlined="true"
                            :dense="true"
                        />
                      </v-col>
                      <v-col class="pa-0 pl-3 pb-2">
                        <InputDatePicker
                            :disabled="carregando"
                            v-model="busca.dtfim2"
                            label="Data Final"
                            :outlined="true"
                            :dense="true"
                        />
                      </v-col>
                      <v-btn
                          :disabled="carregando"
                          class="ml-2 mt-1"
                          color="primary"
                          elevation="0"
                          fab
                          x-small
                          @click="listar()"
                      >
                        <v-icon color="white">mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-divider class="primary" />
                  </template>
                  <template v-if="dados.totalpedidocompra > 0" class="elevation-0">
                    <v-data-table
                        :headers="headersSintetico"
                        :items="dados.pedidocompra"
                        item-key="idempresa"
                        class="elevation-0 fixed-header-table"
                        hide-details
                        hide-default-footer
                        dense
                        :items-per-page="itemsPerPage"
                        height="70vh"
                        fixed-header
                        @click:row="detalhesFilialPedido"
                    >
                      <template v-slot:header.idempresa="{ header }">
                        <span class="header">{{ header.text }}</span>
                      </template>
                      <template v-slot:header.empresa="{ header }">
                        <span class="header">{{ header.text }}</span>
                      </template>
                      <template v-slot:header.valor="{ header }">
                        <span class="header">{{ header.text }}</span>
                      </template>
                      <template v-slot:header.qtdnota="{ header }">
                        <span class="header">{{ header.text }}</span>
                      </template>

                      <template v-slot:item.idempresa="{ item }">
                        <span class="body">{{ item.idempresa }}</span>
                      </template>
                      <template v-slot:item.empresa="{ item }">
                        <span class="body">{{ item.empresa }}</span>
                      </template>
                      <template v-slot:item.valor="{ item }">
                        <span class="body">{{ item.valor | formataDinheiro }}</span>
                      </template>
                      <template v-slot:item.qtdnota="{ item }">
                        <span class="body">{{ item.qtdnota }}</span>
                      </template>
                      <!-- Slot para os totais -->
                      <template #body.append>
                        <tr>
                          <td class="footer"><strong>Total:</strong></td>
                          <td colspan="1"></td>
                          <td class="footer"><strong>{{ dados.totalpedidocompra | formataDinheiro }}</strong></td>
                          <td class="footer"><strong>{{ dados.totalqtdpedidocompra }}</strong></td>
                        </tr>
                      </template>
                    </v-data-table>
                  </template>
                  <template v-else>
                    <v-simple-table dense light>
                      <thead>
                      <tr>
                        <th class="text-center">Cód. Filial</th>
                        <th class="text-center">Filial</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Qtd. Nota</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template>
                        <tr>
                          <td class="text-center" colspan="4">Nenhum registro encontrado.</td>
                        </tr>
                      </template>
                      </tbody>
                    </v-simple-table>
                  </template>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";

export default {
  name: "PedidoCompra",
  mixins: [mixinFilial],
  components: {
    InputDatePicker
  },
  data: () => ({
    headersSintetico: [
      { text: 'Cod.', value: 'idempresa'},
      { text: 'Filial', value: 'empresa'},
      { text: 'Valor', value: 'valor'},
      { text: 'Qtd. Notas', value: 'qtdnota'},
    ],
    headersAnalitico: [
      { text: 'Cod.', value: 'idempresa'},
      { text: 'Filial', value: 'empresa', sortable: false},
      { text: 'Cod. Fornecedor', value: 'idclifor'},
      { text: 'Fornecedor', value: 'cliente'},
      {text: 'Cód. Pedido', value: 'idpedido'},
      { text: 'Data Pedido', value: 'dtmovimento'},
      //{ text: 'Cod. Pagamento', value: 'idpagamento'},
      { text: 'Form. Pagamento', value: 'pagamento', sortable: false},
      { text: 'Valor', value: 'valor'},
    ],
    selected: [],
    expanded: [],
    panels: [2],
    carregandoPedidos: false,
    carregandoPagamentos: false,
    detalhesPedido: false,
    detalhesLancamento: false,
    selectAllChecked: false,
    itemsPerPage: 45,
    itemsPerPageDet: 150,
    valorOrcamento: 10000000,
    dados: {
      notaslancada: [],
      pedidocompra: [],
      totalpedidocompra: 0,
      totalqtdpedidocompra: 0,
      totalnotaslancada: 0,
      totalqtdnotaslancada: 0,
      totalmeta: 0,
    },
    dadosPedidos: {
      notaslancada: [],
      pedidocompra: [],
      totalpedidocompra: 0,
      totalnotaslancada: 0,
    },
    dadosFormaPagamento:[],
    sheet: false,
    carregando: false,
    todasFiliais: ["1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,23,24,25,26,27,28,29,30,31,33,35,36,37,38,39,40"],
    busca: {
      dtinicio: `${new Date().getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-01`,
      dtfim: `${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getDate()}`,
      dtini2: `${new Date().getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-01`,
      dtfim2: `${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getDate()}`,
      idfiliais: [],
      idpedido: 0,
    },
    headerOpen: false,
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.filter((v) => {
          return (
              v.idfilial != 41 &&
              v.idfilial != 42
          );
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    listar() {
      this.carregando = true;
      this.dados.pedidocompra = [];
      this.dados.notaslancada = [];
      this.dados.totalpedidocompra = 0;
      this.dados.totalqtdpedidocompra = 0;
      this.dados.totalnotaslancada = 0;
      this.dados.totalqtdnotaslancada = 0;
      this.dados.totalmeta = 0;
      this.expanded = [];
      return axios
          .post(`${this.backendUrl}compra/pedido/sintetica`, {
            idempresa: this.busca.idfiliais.join(','),
            dtini: this.busca.dtinicio,
            dtfim: this.busca.dtfim,
            dtini2: this.busca.dtini2,
            dtfim2: this.busca.dtfim2
          })
          .then((res) => {
            this.dados = res.data;
            this.dados.totalmeta = this.validaOrcamento();
            this.carregando = false;
          });
    },
    listarPedidos(idempresa, dtini, dtfim, dtini2, dtfim2) {
      this.carregandoPedidos = true;
      this.dadosPedidos.pedidocompra = [];
      this.dadosPedidos.notaslancada = [];
      this.dadosPedidos.totalpedidocompra = 0;
      this.dadosPedidos.totalnotaslancada = 0;
      return axios
          .post(`${this.backendUrl}compra/pedido/analitica`, {
            idempresa: idempresa,
            dtini: dtini,
            dtfim: dtfim,
            dtini2: dtini2,
            dtfim2: dtfim2
          })
          .then((res) => {
            this.dadosPedidos = res.data;
            this.carregandoPedidos = false;
          });
    },
    listarFormaPagamento(idempresa,idclifor,idpedido){
      this.carregandoPagamentos = true;
      this.dadosFormaPagamento = [];
      return axios
          .post(`${this.backendUrl}compra/pedido/formapagto`, {
            idempresa: idempresa,
            idclifor: idclifor,
            idpedido: idpedido,
          })
          .then((res) => {
            this.dadosFormaPagamento = res.data == null ? [] : res.data.map((v) => {
              return {
                ...v,
              };
            });
            this.carregandoPagamentos = false;
          });
    },
    toggleSelectAll(tipo) {
      if (tipo == 'CHECKBOX') {
        if (this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
          console.log('ID das Filiais (como strings):', this.busca.idfiliais);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
      } else {
        if (!this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
        this.selectAllChecked = !this.selectAllChecked;
      }
    },
    detalhesFilialLancado(item){
      this.listarPedidos(String(item.idempresa), this.busca.dtinicio, this.busca.dtfim, this.busca.dtini2, this.busca.dtfim2);
      this.detalhesLancamento = true;
    },
    detalhesFilialPedido(item){
      this.listarPedidos(String(item.idempresa), this.busca.dtinicio, this.busca.dtfim, this.busca.dtini2, this.busca.dtfim2);
      this.detalhesPedido = true;
    },
    rowClass(item) {
      return this.expanded.includes(item) ? 'primary white--text' : '';
    },
    handleRowClick(item) {// toggleExpand(item)
      this.carregandoPagamentos = true;
      this.corIcon = true;
      if (this.expanded.includes(item)) {
        // Se o item já estiver expandido, remove-o da lista de expandidos
        this.listarFormaPagamento(String(item.idempresa), item.idclifor, item.idpedido);
        this.expanded = this.expanded.filter(i => i !== item);
      } else {
        // Caso contrário, define a lista de expandidos para conter apenas o item atual
        this.listarFormaPagamento(String(item.idempresa), item.idclifor, item.idpedido);
        this.expanded = [item];
      }
    },
    validaOrcamento(){
      const dataInicio = new Date(this.busca.dtinicio);
      const dataComparacao = new Date('2024-11-01');
      if(dataInicio >= dataComparacao){
        return this.dados.totalmeta
      }else{
        return this.valorOrcamento;
      }
    },
    async init() {
      this.busca.idfiliais.push(this.todasFiliais);
      await this.listar();
    },
  },
  watch: {
    "busca.dtfim": function () {
      this.get()
      if (this.busca.dtfim < this.busca.dtinicio) {
        this.busca.dtinicio = this.busca.dtfim;
      }
    },
    "busca.dtinicio": function () {
      this.get()
      if (this.busca.dtfim < this.busca.dtinicio) {
        this.busca.dtfim = this.busca.dtinicio;
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.header{
  height: 25px !important;
  font-size: 18px !important;
}
.body{
  height: 20px !important;
  font-size: 16px !important;
}
.footer{
  height: 20px !important;
  font-size: 16px !important;
}

.tableExp {
  margin: 0px 0px 10px 0px;
}

.headerExp th {
  background-color: #CFD8DC !important;
  height: 25px !important;
}

.bodyExp td {
  height: 20px !important;
  font-size: larger;
}

.footExp td {
  height: 20px !important;
  font-weight: bold;
}

.v-table > .v-table__wrapper > table > tbody > tr > td,
.v-table > .v-table__wrapper > table > tbody > tr > th,
.v-table > .v-table__wrapper > table > thead > tr > td,
.v-table > .v-table__wrapper > table > thead > tr > th,
.v-table > .v-table__wrapper > table > tfoot > tr > td,
.v-table > .v-table__wrapper > table > tfoot > tr > th {
  padding: 0 4px;
}
</style>